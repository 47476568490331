// .tabbar{
//   opacity: 0;
//   height: 0;
//   &:hover{
//     opacity: 1;
//     height: 60px;
//   }
// }

@media (min-width: 769px) {
  .tabbar {
    opacity: 0;
    height: 0;

    &:hover {
      opacity: 1;
      height: 60px;
    }
  }
}